@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");

@font-face {
  font-family: malmo;
  src: url("/src/components/layout/malmo.otf") format("opentype");
}

* {
  box-sizing: border-box;
  font-family: malmo;
}

html {
}

body {
  background: url("/src/components/layout/bluebackground.jpg");
  background-repeat: repeat;
  background-size: contain;

  background: url("/src/components/layout/bluebackground.jpg") no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

h1 {
  font-size: 2.5rem;
  color: #000000;
}

h2 {
  font-size: 1.5rem;
  color: #eeeeee;
}

p {
  color: #eeeeee;
}
