.header {
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;

  background-color: white;

  top: 0;
  z-index: 1;
}

.logo {
  font-size: 1rem;
  color: black;
  font-weight: bold;
}

.header ul {
  margin: 0;
  list-style: none;
  padding: 0;
  display: flex;
  align-items: baseline;
}

@font-face {
  font-family: malmo;
  src: url("malmo.otf") format("opentype");
}

.header li {
  margin-left: 3rem;
  font-family: malmo, Geneva, Tahoma, sans-serif;
}

.header img {
  width: 3rem;
  height: auto;
  margin-left: 3rem;
}

.header a {
  text-decoration: none;
  font-size: 1rem;
  color: black;
}

.header a:hover,
.header a:active,
.header a.active {
  color: black;
}
