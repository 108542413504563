.card {
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 0px;
  padding: 30px;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 3%;
}
